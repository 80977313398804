<template>
  <v-container>
    <div class="white--text text-center">
      <h2 class="mb-5">Coming Soon:</h2>

      <h1>Sony's New Consumer End User Rebate Center</h1>
      <p class="mb-5"></p>
      <p class="mb-0">Please check back soon</p>
      <p>Thank you</p>
    </div>
  </v-container>
</template>

<script>
export default {
  name: "CeurComingSoon",
  metaInfo: {
    title: "Coming Soon"
  }
};
</script>
